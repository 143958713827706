import { render, staticRenderFns } from "./DicasUpload.vue?vue&type=template&id=21f8ecd1&scoped=true"
import script from "./DicasUpload.vue?vue&type=script&lang=js"
export * from "./DicasUpload.vue?vue&type=script&lang=js"
import style0 from "./DicasUpload.vue?vue&type=style&index=0&id=21f8ecd1&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f8ecd1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/josefernandocarlos/Projects/recuperi/recuperi-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21f8ecd1')) {
      api.createRecord('21f8ecd1', component.options)
    } else {
      api.reload('21f8ecd1', component.options)
    }
    module.hot.accept("./DicasUpload.vue?vue&type=template&id=21f8ecd1&scoped=true", function () {
      api.rerender('21f8ecd1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DicasUpload.vue"
export default component.exports